#projects {
    min-height: 100vh;
    display: fixed;
    position: relative;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__projects {
    width: 100%;
    height: 100%;

    flex: 1;
    display: flex;
    flex-direction: column;

    padding: 1rem 1rem;
    align-items: center;
    justify-content: flex-start;
}

.app__projects-list {
    height: 100%;
    width: 100%;

    flex: 1;
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    overflow-y: hidden;
    overflow-x: scroll;
}

.app__projects-box {
    min-width: 45%;
    height: 80%;

    margin: 1rem;

    flex: 1;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;

    border-radius: 50px;
    box-shadow: 0 0 40px rgba($color: #a8a8a8, $alpha: 0.15);

    background: linear-gradient(135deg, #e2e2e2, #f5f5f5 25%, #d1d1d1 50%, #f5f5f5 75%, #e2e2e2);

    @media screen and (max-width: 1000px) {
        min-width: 450px;
        height: 85%;

        padding: 1rem;
    }

    @media screen and (max-width: 450px) {
        min-width: 350px;
        height: 75%;

        padding: 1rem;
    }
}

.app__projects-box-logo {
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin: 1rem;

    img {
        height: 100px;

        user-select: none;

        @media screen and (max-width: 1000px) {
            height: 75px;
        }
    }
}

.app__projects-box-title {
    width: 100%;
    margin: 1rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;
}

.app__projects-box-lang {
    width: 100%;
    margin: 1rem 2rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    p {
        color: var(--primary-color);

        margin: 1rem 2rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
    }
}

.app__projects-box-desc {
    width: 100%;
    margin: 1rem 1rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    @media screen and (max-width: 450px) {
        display: none;
        size: 0px;
    }
}

.app__projects-box-button {
    width: 100%;

    margin: 1rem 1rem 3rem 1rem;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-direction: column;

    button {
        padding: 1rem;

        border-radius: 25px;
        border: none;

        background-color: var(--tertiary-color);

        p {
            color: var(--primary-color);

            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            text-align: center;

            cursor: pointer;
        }
    }
}