.app__model {
    width: 100%;
    height: 100%;

    position: absolute;
    align-items: center;
    justify-content: center;
    touch-action: none;
    pointer-events: none;
    z-index: -1;
}