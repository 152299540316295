.app__navbar {
    width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 0.5rem 2rem;
    background: rgba($color: var(--primary-color), $alpha: 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba($color: var(--primary-color), $alpha: 0.18);
    position: fixed;
    z-index: 3;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        width: 45px;
        height: 45px;

        @media screen and (min-width: 2000px) {
            width: 120px;
            height: 70px;
        }
    }
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: flex-start;

    li {
        margin: 0 1rem;
        cursor: pointer;

        flex-direction: column;

        &:hover {
            div {
                width: 100%;
                background-position: 0;
                background: var(--tertiary-color);
            }

            a {
                color: var(--tertiary-color);
            }
        }

        div {
            width: 0;
            height: 2.5px;
            border-radius: 2.5px;
            background: transparent;

            margin-top: 5px;

            transition: all 0.3s ease-in-out;
        }

        a {
            color: var(--secondary-color);
            text-decoration: none;
            flex-direction: column;

            text-transform: uppercase;
            
            transition: all 0.3s ease-in-out;
        }
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.app__navbar-contact-links {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    padding: 0 1rem;

    li {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin: 0 0.25rem;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: all 0.3s ease-in-out;

        cursor: pointer;

        svg {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 20px;
            height: 20px;
            border-radius: 25%;
            color: var(--secondary-color);
        }

        &:hover {

            svg {
                color: var(--tertiary-color);
            }
        }

        @media screen and (min-width: 2000px) {
            width: 70px;
            height: 70px;

            margin: 0.5rem 0;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
    
}

.app__navbar-menu {
    width: 45px;
    height: 45px;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 65%;
        height: 65%;
        color: var(--secondary-color);

        cursor: pointer;
    }

    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        padding: 1rem;

        width: 35%;
        height: 100vh;

        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;

        background-color: var(--primary-color);

        box-shadow: 0 0 20px rgba($color: #a8a8a8, $alpha: 0.15);

        svg {
            width: 25px;
            height: 25px;
            color: var(--secondary-color);

            cursor: pointer;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 10%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li {
                margin: 1rem;

                a {
                    color: var(--secondary-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 400;

                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: var(--tertiary-color);
                    }
                }
            }
        }

        @media screen and (min-width: 900px) {
            display: none;
        }
    }

    @media screen and (min-width: 900px) {
        display: none;
    }
}