#about {
    min-height: 100vh;
    display: fixed;
    position: relative;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__about {
    width: 100%;
    height: 100%;

    flex: 1;
    display: flex;
    flex-direction: column;

    padding: 0 1rem;
    align-items: center;
    justify-content: flex-start;
}

.app__about-overview {
    width: 100%;
    height: 100vh;

    flex: 1;
    display: flex;
    flex-direction: row;

    margin-top: 5rem;

    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        flex-direction: column;

        justify-content: flex-start;
    }

}

.app__about-img {
    width: 35%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 75%;

        border-radius: 35% 35% 5% 5%;

        box-shadow: 0 0 20px rgba($color: white, $alpha: 0.15);

        user-select: none;

        @media screen and (max-width: 1000px) {
            max-width: 50%;
        }
    }

    @media screen and (max-width: 1000px) {
        width: 100%;
        height: fit-content;

        margin-bottom: 2rem;
        align-items: center;
    }
}

.app__about-intro {
    width: 65%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        width: 100%;
        height: fit-content;
    }

    div {
        @media screen and (max-width: 1000px) {
            width: 100%;

            flex: 1;
            display: flex;
            flex-direction: column;

            justify-content: center;
            align-items: center;
        }

        P {
            @media screen and (max-width: 1000px) {
                width: 90%;
                text-align: center;
            }
        }
    }
}

.app__about-experience {
    width: 95%;
    min-height: 75vh;

    display: flex;
    flex-direction: row;

    position: relative;
    align-items: flex-end;
    justify-content: flex-start;

    overflow-y: hidden;
    overflow-x: scroll;

    @media screen and (max-width: 1000px) {
        min-height: 50vh;
        flex-direction: row;
        flex-wrap: wrap;

        align-items: center;
    }
}

.app__about-experience-box {

    min-height: 65vh;
    max-height: 65vh;
    width: 22.5%;
    min-width: 360px;
    margin: 1rem;

    flex: 1;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;

    border-radius: 50px;
    box-shadow: 0 0 40px rgba($color: #a8a8a8, $alpha: 0.15);

    @media screen and (max-width: 1000px) {
        min-width: 35vw;
        min-height: 250px;
        max-height: 250px;

        align-items: flex-start;
        padding: 1rem;
    }

    @media screen and (max-width: 450px) {
        min-width: 45vw;
        min-height: 200px;
        max-height: 200px;

        padding: 1rem;
    }
}

.app__about-experience-logo {
    min-height: 175px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;


    img {
        max-height: 120px;
        max-width: 350px;

        user-select: none;

        @media screen and (max-width: 1000px) {
            max-width: 70%;
            width: auto;
        }
    }

    @media screen and (max-width: 1000px) {
        min-height: 120px;
    }

    @media screen and (max-width: 450px) {
        min-height: 125px;
    }
}

.app__about-experience-intro {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    p {
        color: var(--primary-color);

        margin: 0rem;
        text-align: center;
    }

    @media screen and (max-width: 1000px) {
        margin-bottom: 1rem;
        align-items: center;
    }
    
}

.app__about-experience-lang {
    margin: 1rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    p {
        color: var(--primary-color);

        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
    }

    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.app__about-experience-desc {
    margin: 1rem 2rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    p {
        text-align: left;
    }

    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.app__about-intro-title {
    margin-bottom: 1rem;
}

.app__about-intro-desc {
    p {
        margin-bottom: 2rem;
    }
}