@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --font-base: "Montserrat", sans-serif;

    --primary-color: #0F1035;
    --secondary-color: #DCF2F1;
    --tertiary-color: #7FC7D9;
    --lightgreen-color: #8ee4af;
    --mediumgreen-color: #7de2a9;
    --darkgreen-color: #379683;
    --black-color: #030303;
    --lightgray-color: #e4e4e4;
    --gray-color: #6b7688;
    --white-color: #ffffff;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}