#skills {
    width: 100%;

    display: fixed;
    position: relative;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__skills {
    width: 100%;
    height: 100%;

    flex: 1;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.app__skills-row {
    width: 100%;
    height: 100%;

    flex: 1;
    display: flex;
    flex-direction: row;

    overflow: hidden;

    align-items: center;
    justify-content: center;
}

.app__skills-obj {
    margin: 5rem 2rem 0 2rem;

    height: 150px;
    width: 150px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    background: linear-gradient(135deg, #fdf9f7, #f7e8e4 25%, #f0d6d0 50%, #f7e8e4 75%, #fdf9f7);

    transition: all 0.3s ease-in-out;

    img {
        width: 60%;
        height: auto;

        background-position: center;
        background-clip: content-box;
    }

    @media screen and (max-width: 1000px) {
        height: 100px;
        width: 100px;
    }

    @media screen and (max-width: 850px) {
        height: 85px;
        width: 85px;
    }
}